export const Facebook = (props) => (
  <svg
    {...props}
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 0C7.163 0 0 7.163 0 16s7.163 16 16 16 16-7.163 16-16S24.837 0 16 0zm6.104 8.148l-2.213.001c-1.735 0-2.07.825-2.07 2.035v2.668h4.137l-.002 4.178H17.82v10.724h-4.316V17.03H9.896v-4.178h3.608V9.77c0-3.577 2.185-5.524 5.376-5.524l3.224.005v3.897z"
      fill="#fff"
    />
  </svg>
);
