export const Youtube = (props) => (
  <svg
    {...props}
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.007 18.998L19.21 16l-5.204-2.998v5.996z" fill="#fff" />
    <path
      d="M16 0C7.165 0 0 7.165 0 16s7.165 16 16 16 16-7.165 16-16S24.835 0 16 0zm9.998 16.016s0 3.245-.412 4.81a2.505 2.505 0 01-1.762 1.762C22.258 23 16 23 16 23s-6.242 0-7.823-.428a2.506 2.506 0 01-1.763-1.763C6.002 19.261 6.002 16 6.002 16s0-3.245.412-4.81a2.556 2.556 0 011.763-1.778C9.74 9 16 9 16 9s6.259 0 7.823.428c.857.23 1.532.906 1.763 1.763.428 1.564.412 4.825.412 4.825z"
      fill="#fff"
    />
  </svg>
);
